<template>
  <div class="">
    <div v-if="scriptsLoaded">
      <!-- ApexChart component will be rendered here once scripts are loaded -->
      <apexcharts
        class="chartContainer"
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
        @dataPointSelection="handleClick"
      ></apexcharts>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      scriptsLoaded:false,
      chartOptions: {
        colors: ["#009DFF"],
        chart: {
          fontFamily: '"Inter", sans-serif',
          foreColor: "#2F3747",
          height: 350,

          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          style: {
            fontSize: 14,
            fontFamily: '"Inter", sans-serif',
            fontWeight: 800,
            colors: ["#009DFF"],
          },
        },
        fill: {
          opacity: 1,
          type: "solid",
          colors:['transparent']
          // colors: [
          //   function ({ value }) {
          //     if (value == 100) {
          //       return "hsl(11.78deg 51.2% 59.02%)";
          //     } else {
          //       return "transparent";
          //     }
          //   },
          // ],
        },

        grid: {
          borderColor: "#E3E8EF",
          strokeDashArray: [4, 4],
        },

        yaxis: {
          tickAmount: 2, // Display only 2 ticks on the y-axis
          min: 0, // Set the minimum value of the y-axis
          max: 100, // Set the maximum value of the y-axis
          tickPlacement: "between", // Place the ticks between the bars
          labels: {
            formatter: function (value) {
              if (value === 50) {
                return value + "%"; // Return an empty string for the value 50
              } else {
                return value + "%"; // Add the '%' symbol to other values
              }
            },
          },
        },
        legend: {
          show:false
        },
        plotOptions: {
          bar: {
            columnWidth: "95%", // Set the column width to 100% to remove the gap between bars
            distributed: true,
            dataLabels: {
              position: "top",
               
            },
           

            borderRadius: 10,
            
          },
        },
        tooltip: {
          enabled: false, // Set tooltip enabled to false
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        xaxis: {
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          axisBorder: {
            offsetY: -1,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },labels: {
           
            style: {
              colors: [],
              fontSize: "24px",
              fontFamily: "Open Sans",
              cssClass: "apexcharts-xaxis-label font-weight-bold",
            },
           
          },
        },

        // Add more styling options as per your requirements
      },
      series: [
        {
          //   name: 'Series 1',
          // data: [50, 100, 30, 21, 80, 31, 50],
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    handleClick(event, chartContext, config) {
      if (config.dataPointIndex !== undefined) {
        let baseUrL = `https://www.tcheletvelavan.com/` + (config.dataPointIndex + 1)
        
        window.open(baseUrL,"_blank")
      }
    },
    initApexCharts() {
      this.loadScript('https://cdn.jsdelivr.net/npm/apexcharts')
        .then(() => {
          return this.loadScript('https://cdn.jsdelivr.net/npm/vue-apexcharts');
        })
        .then(() => {
         

          // Registering ApexCharts as a global component
          Vue.component('apexcharts', window.VueApexCharts);
          this.scriptsLoaded = true;
          return 
          
        })
        .catch(err => {
          throw err
        });
    },
  },
  computed: {
    ...mapGetters(["getTotalPreviewPages", "getSelectedOptions"]),

    getSelectedOptionsData() {
      return this.getSelectedOptions.questionAnswer;
    },
    getPreviewPages() {
      return this.getTotalPreviewPages;
    },

    getTotalQuestionPages() {
      let QuestionPages = this.getPreviewPages.filter(
        (x) => x.value == "singleAnswer" || x.value == "multipleAnswer"
      );

      return QuestionPages;
    },

    
    CalculateOptionIndexArray() {
      const selectedOptionsData = this.getSelectedOptionsData;
      let optionsPriorityArray = [];

      selectedOptionsData.forEach((item) => {

         const selectedOptionCountMap = {};
        const matchingPage = this.getTotalQuestionPages.find(
          (page) => page.id === item.questionId
        );


        if (matchingPage) {
          let optionIndex = matchingPage.pageDesign.blocksArray.findIndex(
            (block) => block.type == "option"
          );

          if (optionIndex > -1) {
           
            item.selectedOption.forEach((option) => {
              let selectedOptionIndex = matchingPage.pageDesign.blocksArray[
                optionIndex
              ].options.findIndex((opt) => opt.id == option.id);

              if (selectedOptionIndex > -1) {
                
                if (selectedOptionCountMap[selectedOptionIndex]) {
                  selectedOptionCountMap[selectedOptionIndex]++;
                } else {
                  selectedOptionCountMap[selectedOptionIndex] = 1;
                }

               
              }
            });

             
          }

          optionsPriorityArray.push(selectedOptionCountMap);
        }
      });

      return optionsPriorityArray;
    },

    CalculateFinalPercentArray(){
      const totalCount = this.CalculateOptionIndexArray.length;

       // Calculate the count for each index
  const indexCounts = this.CalculateOptionIndexArray.reduce((counts, obj) => {
    Object.entries(obj).forEach(([index, count]) => {
      if (!Object.prototype.hasOwnProperty.call(counts, index)) {
        counts[index] = 0;
      }
      counts[index] += count;
    });
    return counts;
  }, {});

  // Calculating the percentage for each index
  const indexPercentages = Array.from({ length: 9 }, (_, i) => {
    const index = i.toString();
    const count = indexCounts[index] || 0;
    const percentage = Math.round((count / totalCount) * 100);
    // return { [index]: percentage };
    return  percentage ;
  });

  return indexPercentages;
    }
  },
 mounted() {
   
    this.series = [{ data: this.CalculateFinalPercentArray }];
    this.initApexCharts();
    //
  },
};
</script>


<style scoped>
.chartContainer >>> .apexcharts-canvas svg .apexcharts-bar-series {
  cursor: pointer !important; 
}
</style>